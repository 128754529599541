import { energySuppliersDomiciliationRequired } from '@app/energy/constants/energy.constants';
import { EnergySupplier } from '@app/energy/enums/energy-supplier.enum';
import { InjectionUsage } from '@app/energy/enums/injection-usage.enum';
import { DnbMatch } from '@app/energy/interfaces/dnb-match';
import { Move } from '@app/move/interfaces/move';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { DateUtils, numberOfDaysPerWeek, TimeUnit } from '@smooved/core';
import { ElectricityMeterType } from '@smooved/ui';
import { Moment } from 'moment';

export class EnergyUtils {
    public static isSingleMeter(meterType: ElectricityMeterType): boolean {
        return meterType === ElectricityMeterType.Single;
    }

    public static isDoubleMeter(meterType: ElectricityMeterType): boolean {
        return meterType === ElectricityMeterType.Double || meterType === ElectricityMeterType.Unknown;
    }

    public static isDigitalMeter(meterType: ElectricityMeterType): boolean {
        return meterType === ElectricityMeterType.Digital;
    }

    public static hasElectricity(energyType: EnergyType): boolean {
        return energyType === EnergyType.Both || energyType === EnergyType.Electricity;
    }

    public static hasGas(energyType: EnergyType): boolean {
        return energyType === EnergyType.Both || energyType === EnergyType.Gas;
    }

    public static hasSolarPanels(move: Move): boolean {
        return !!move?.energyOffer?.hasSolarPanels;
    }

    public static hasInjection(dnbMatch: DnbMatch, move: Move): boolean {
        if (!EnergyUtils.hasSolarPanels(move)) return false;
        const { injectionUsage } = dnbMatch;
        const { meterType } = move.energyOffer || {};
        return (
            (injectionUsage === InjectionUsage.DigitalMeters && meterType === ElectricityMeterType.Digital) ||
            injectionUsage === InjectionUsage.AllMeters
        );
    }

    public static getCanEditMeterReadingsDate(movingDate: Date): Moment {
        return DateUtils.tz(movingDate).subtract(numberOfDaysPerWeek, TimeUnit.Days);
    }

    public static canEditMeterReadings(movingDate: Date): boolean {
        return DateUtils.tz().isSameOrAfter(EnergyUtils.getCanEditMeterReadingsDate(movingDate));
    }

    public static isSupplier(move: Pick<Move, 'energyOffer'>, supplier: EnergySupplier): boolean {
        return move?.energyOffer?.energyOfferSelection?.supplier?.toLowerCase() === supplier.toLowerCase();
    }

    public static energyDomiciliationRequired(move: Pick<Move, 'energyOffer'>): boolean {
        if (!move?.energyOffer) return false;
        return (
            move.energyOffer.energyOfferSelection?.brussels &&
            energySuppliersDomiciliationRequired.some((supplier) => EnergyUtils.isSupplier(move, supplier))
        );
    }
}
