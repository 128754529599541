import { getRoleState } from '@app/authentication/state/authentication.reducer';
import { Transferee } from '@app/move/enums/transferee.enum';
import { UiOfferType } from '@app/move/enums/ui-offer-type.enum';
import { Move } from '@app/move/interfaces/move';
import { UiOffer } from '@app/move/interfaces/ui-offer';
import { PaymentType } from '@app/wizard/complete/enums/payment-type';
import { EnergySuggestion } from '@app/wizard/energy/interfaces/energy-suggestion';
import { EmojiIcon } from '@app/wizard/icon/models/emoji-icon.enum';
import { TelecomBundle } from '@app/wizard/telecom/telecom.interfaces';
import { Action, createFeatureSelector, createReducer, createSelector, MemoizedSelector, on } from '@ngrx/store';
import { AddressUtils, DbUtils, ObjectUtils, Role } from '@smooved/core';
import { isDate, isObject, set } from 'lodash';
import { MoveActions, MoveActionTypes, PatchPropertySuccess } from './move.actions';
import { MoveUtils } from './move.utils';
import { updateTransaction } from '@app/services/state/services.actions';
import { selectOrderedBoilerMaintenanceContract } from '@app/services/state/services.selectors';

export const getInitialMoveState = (): any => {
    return {
        telecomOffer: {
            telecomBundle: {
                [TelecomBundle.Internet]: true,
            },
        },
    };
};

const isSameMove = (state: any, action: PatchPropertySuccess): boolean => {
    return action.payload.newState && state._id === action.payload.newState._id && action.payload?.persistToState;
};

export const transactionReducer = createReducer(
    { ...getInitialMoveState() },
    on(updateTransaction, (state, { transaction }) => ObjectUtils.merge2Objects(state, transaction))
);

export function moveReducer(state = { ...getInitialMoveState() }, action: MoveActions) {
    switch (action.type) {
        case MoveActionTypes.SetMoveState:
            return { ...action.payload.move };

        case MoveActionTypes.PatchRealEstateAgent:
            return {
                ...state,
                ...{
                    realEstateAgent: action.payload,
                },
            };

        case MoveActionTypes.PatchMoveState:
            let patchedPart;
            if (action.payload.propertyPath) {
                const rootPathCurrentValue = { ...state[action.payload.rootPath] };
                patchedPart = {
                    [action.payload.rootPath]: {
                        ...set(rootPathCurrentValue, action.payload.propertyPath, action.payload.value),
                    },
                };
            } else {
                patchedPart = {
                    [action.payload.rootPath]:
                        isObject(action.payload.value) && !isDate(action.payload.value)
                            ? { ...state[action.payload.rootPath], ...action.payload.value }
                            : action.payload.value,
                };
            }
            return Object.assign({}, state, patchedPart);

        case MoveActionTypes.PatchPropertySuccess:
            return { ...(isSameMove(state, action) ? action.payload.newState : state) };

        case MoveActionTypes.CreateMoveSuccess:
            return {
                ...state,
                _id: action.payload.id,
            };

        case MoveActionTypes.GetMoveByAccessTokenAndIdSuccess:
        case MoveActionTypes.ConfirmSuccess:
            return {
                ...state,
                ...action.payload.move,
            };

        default:
            return state;
    }
}

export function combinedReducer(state = { ...getInitialMoveState() }, action: Action) {
    const mReducer = moveReducer(state, action as any);
    return transactionReducer(mReducer, action);
}

export const getMoveState = createFeatureSelector<Move>('move');

export const getHasAMoveState = createSelector(getMoveState, (moveState) => !!moveState?._id);

export const getEnergyConsumptionKnownState = createSelector(getMoveState, (moveState) => moveState?.consumptionKnown);

export const getCurrentEnergySupplierState = createSelector(getMoveState, (moveState) => moveState?.currentEnergySupplier);

export const getElectricitySingleConsumptionState = createSelector(getMoveState, (moveState) => moveState?.electricitySingleConsumption);

export const getElectricityDoubleDayConsumptionState = createSelector(
    getMoveState,
    (moveState) => moveState?.electricityDoubleDayConsumption
);

export const getElectricityDoubleNightConsumptionState = createSelector(
    getMoveState,
    (moveState) => moveState?.electricityDoubleNightConsumption
);

export const getElectricityExclusiveNightConsumptionState = createSelector(
    getMoveState,
    (moveState) => moveState?.electricityExclusiveNightConsumption
);

export const getGasConsumptionState = createSelector(getMoveState, (moveState) => moveState?.gasConsumption);

export const getMonthlyEnergyAdvanceAmount = createSelector(getMoveState, (moveState) => moveState?.monthlyEnergyAdvanceAmount);

export const getMovingDateState = createSelector(getMoveState, (moveState) => moveState?.movingDate);

export const getCreatedByFlowState = createSelector(getMoveState, (moveState) => moveState?.createdByFlow);

export const getUserState = createSelector(getMoveState, (moveState) => moveState?.user);

export const getFirstNameState = createSelector(getUserState, (userState) => userState?.firstName);

export const getLastNameState = createSelector(getUserState, (userState) => userState?.lastName);

export const getGenderState = createSelector(getUserState, (userState) => userState?.gender);

export const getPhoneNumberState = createSelector(getUserState, (userState) => userState?.phoneNumber);

export const getEmailState = createSelector(getUserState, (userState) => userState?.email);

export const getCurrentAddress = createSelector(getUserState, (userState) => userState?.currentAddress);

export const getCurrentAddressIcons = createSelector(getCurrentAddress, (currentAddress) => {
    if (currentAddress && !!Object.keys(currentAddress).length) {
        return [EmojiIcon.ContactAddress];
    } else {
        return null;
    }
});

export const getFormattedCurrentAddress = createSelector(
    getCurrentAddress,
    (currentAddress) => currentAddress && AddressUtils.buildFormattedAddress(currentAddress)
);

export const getMovingAddress = createSelector(getUserState, (userState) => userState?.movingAddress);

export const getMovingAddressIcons = createSelector(getMovingAddress, (movingAddress) => {
    if (movingAddress && !!Object.keys(movingAddress).length) {
        return [EmojiIcon.ContactAddress];
    } else {
        return null;
    }
});

export const getFormattedMovingAddress = createSelector(
    getMovingAddress,
    (movingAddress) => movingAddress && AddressUtils.buildFormattedAddress(movingAddress)
);

export const getContactAddress = createSelector(getUserState, (userState) => userState?.contactAddress);

export const getHasLinkedMoveState = createSelector(getMoveState, (moveState) => !!moveState?.linkedMove);

export const getIsLeaverState = createSelector(getUserState, (userState) => userState?.role === 'leaver');

export const getLandAgentState = createSelector(getUserState, (userState) => !!userState?.landAgent);

export const getTransfereeTypeState = createSelector(getUserState, (userState) => userState?.transfereeType);

export const getTransfereeIsTenantState = createSelector(
    getTransfereeTypeState,
    (transfereeState) => transfereeState === Transferee.TenantTransferee
);

export const getMetaState = createSelector(getMoveState, (moveState) => moveState?.meta);

export const getFamilyMemberCountState = createSelector(getMetaState, (metaState) => metaState?.familyMemberCount);

export const getHomeDescriptionState = createSelector(getMetaState, (metaState) => metaState?.homeDescription);

export const getHomeDescriptionSizeState = createSelector(getMetaState, (metaState) => metaState?.homeDescriptionSize);

export const getHomeDescriptionApartmentLevelState = createSelector(getMetaState, (metaState) => metaState?.homeDescriptionApartmentLevel);

export const getHomeConstructionYearState = createSelector(getMetaState, (metaState) => metaState?.homeConstructionYear);

export const getTelecomOfferState = createSelector(getMoveState, (moveState) => moveState?.telecomOffer);

export const getTelecomBundleState = createSelector(getTelecomOfferState, (telecomOfferState) => telecomOfferState?.telecomBundle);

export const getDigitalStreamTelevisionState = createSelector(
    getTelecomOfferState,
    (telecomOfferState) => telecomOfferState?.digitalStreamTelevision
);

export const getFixedLineState = createSelector(getTelecomOfferState, (telecomOfferState) => telecomOfferState?.fixedLine);

export const getMobileBundlesState = createSelector(getTelecomOfferState, (telecomOfferState) => telecomOfferState?.mobileBundle);

export const getHasInternetState = createSelector(
    getTelecomBundleState,
    (telecomBundleState) => !!telecomBundleState && !!telecomBundleState[TelecomBundle.Internet]
);

export const getHasDigitalTelevisionState = createSelector(
    getTelecomBundleState,
    (telecomBundleState) => !!telecomBundleState && !!telecomBundleState[TelecomBundle.DigitalTelevision]
);

export const getHasMobilePhoneState = createSelector(
    getTelecomBundleState,
    (telecomBundleState) => !!telecomBundleState && !!telecomBundleState[TelecomBundle.MobilePhone]
);

export const getHasFixedLineState = createSelector(
    getTelecomBundleState,
    (telecomBundleState) => !!telecomBundleState && !!telecomBundleState[TelecomBundle.FixedLine]
);

export const getProviderChoiceState = createSelector(getTelecomOfferState, (telecomOfferState) => telecomOfferState?.providerChoice);

export const getTvBundleState = createSelector(getTelecomOfferState, (telecomOfferState) => telecomOfferState?.tvBundles);

export const getTelecomInstallationState = createSelector(
    getTelecomOfferState,
    (telecomOfferState) => telecomOfferState?.telecomInstallation
);

export const getTelecomInstallationDateByAdminState = createSelector(
    getMoveState,
    (moveState) => !!moveState?.telecomInstallationDateByAdmin
);

export const getTelecomPhoneTransferByAdminState = createSelector(getMoveState, (moveState) => moveState?.telecomPhoneTransferByAdmin);

export const getTelecomInstallationCompleteState = createSelector(
    getTelecomInstallationState,
    getTelecomInstallationDateByAdminState,
    (telecomInstallationState, telecomInstallationDateByAdminState) =>
        telecomInstallationDateByAdminState || (!!telecomInstallationState?.date && !!telecomInstallationState?.timeSlot)
);

export const getTelecomOfferSelectionState = createSelector(
    getTelecomOfferState,
    (telecomOfferState) => telecomOfferState?.telecomOfferSelection
);

export const getTelecomTransferState = createSelector(getMoveState, (moveState) => moveState?.telecomTransfer);

export const getTelecomTransferSuccessState = createSelector(getTelecomTransferState, getUserState, (telecomTransferState, userState) =>
    MoveUtils.telecomTransferSuccess(telecomTransferState, userState)
);

export const getTelecomStopState = createSelector(getMoveState, (moveState) => moveState?.telecomStop);

export const getTelecomStopSuccessState = createSelector(
    getTelecomStopState,
    getUserState,
    (telecomStopState, userState) =>
        !!telecomStopState &&
        !!telecomStopState.currentSupplier &&
        !!telecomStopState.cancellationDate &&
        !!telecomStopState.fixedLine &&
        !!telecomStopState.easySwitchCode &&
        !!userState &&
        !!userState.currentAddress
);

export const getTelecomPaymentType = createSelector(getMoveState, (moveState) => moveState.telecomPaymentType);

export const getTelecomConfirmedState = createSelector(getMoveState, (moveState) => !!moveState.telecomConfirmed);

export const getTelecomStopConfirmedState = createSelector(getMoveState, (moveState) => !!moveState.telecomStopConfirmed);

export const getTelecomTransferConfirmedState = createSelector(getMoveState, (moveState) => !!moveState.telecomTransferConfirmed);

export const getTelecomSelectedState = createSelector(getMoveState, (moveState) => !!moveState.telecomSelected);

export const getMobileBundleCompleteState = createSelector(
    getTelecomOfferSelectionState,
    getHasMobilePhoneState,
    getHasFixedLineState,
    getFixedLineState,
    getMobileBundlesState,
    getTelecomPhoneTransferByAdminState,
    (telecomOfferSelection, hasMobilePhone, hasFixedLine, fixedLine, mobileBundlesState, telecomPhoneTransferByAdminState) => {
        if (!telecomOfferSelection || telecomPhoneTransferByAdminState) {
            return true;
        }

        // fixed line and mobile phone
        if (hasFixedLine && hasMobilePhone) {
            return !!fixedLine && !!mobileBundlesState?.length;
        }

        // fixed line
        else if (hasFixedLine) {
            return !!fixedLine;
        }

        // mobile phone
        else if (hasMobilePhone) {
            return !!mobileBundlesState?.length;
        }

        return true;
    }
);

export const getTelecomOrderedState = createSelector(getMoveState, (moveState) => !!moveState.telecomOrdered);

export const getTelecomOrderedByAdminState = createSelector(getMoveState, (moveState) => !!moveState.telecomOrderedByAdmin);

export const getTelecomOfferSelectedState = createSelector(
    getMoveState,
    (moveState) => !!moveState.telecomOffer?.telecomOfferSelection && !!moveState?.telecomSelected
);

export const getTelecomTransferSelectedState = createSelector(
    getMoveState,
    (moveState) => !!moveState?.telecomTransfer && !!moveState?.telecomSelected
);

export const getTelecomOfferOrTransferSelectedState = createSelector(
    getTelecomOfferSelectedState,
    getTelecomTransferSelectedState,
    (telecomOfferSelected, telecomTransferSelected) => {
        return !!telecomOfferSelected || !!telecomTransferSelected;
    }
);

export const getTelecomCompleteState = createSelector(
    getTelecomInstallationCompleteState,
    getMobileBundleCompleteState,
    (telecomInstallationComplete, mobileBundleComplete) => telecomInstallationComplete && mobileBundleComplete
);

export const getIsTelecomCompleteState = createSelector(
    getTelecomOfferSelectedState,
    getTelecomCompleteState,
    (telecomOfferSelected, telecomComplete) => {
        return telecomOfferSelected && telecomComplete;
    }
);

export const getEnergyOfferState = createSelector(getMoveState, (moveState) => moveState?.energyOffer);

export const getEnergyTypeState = createSelector(getEnergyOfferState, (energyOfferState) => energyOfferState?.energyType);

export const getMeterTypeState = createSelector(getEnergyOfferState, (energyOfferState) => energyOfferState?.meterType);

export const getHasExclusiveNightMeterState = createSelector(
    getEnergyOfferState,
    (energyOfferState) => !!energyOfferState?.hasExclusiveNightMeter
);

export const getHeatElectricityState = createSelector(getEnergyOfferState, (energyOfferState) => !!energyOfferState?.heatElectricity);

export const getHasSolarPanelsState = createSelector(getEnergyOfferState, (energyOfferState) => !!energyOfferState?.hasSolarPanels);

export const getConvertPowerSolarPanelsState = createSelector(
    getEnergyOfferState,
    (energyOfferState) => energyOfferState?.convertPowerSolarPanels
);

export const getEanCodeGasCurrentState = createSelector(getMoveState, (moveState) => moveState?.eanCodeGasCurrent);

export const getEanCodeElectricityCurrentState = createSelector(getMoveState, (moveState) => moveState?.eanCodeElectricityCurrent);

export const getGasMeterReadingState = createSelector(getMoveState, (moveState) => moveState?.gasMeterReading);

export const getElectricityDoubleDayMeterReadingState = createSelector(
    getMoveState,
    (moveState) => moveState?.electricityDoubleDayMeterReading
);

export const getElectricityDoubleNightMeterReadingState = createSelector(
    getMoveState,
    (moveState) => moveState?.electricityDoubleNightMeterReading
);

export const getElectricityDoubleNightExclusiveMeterReadingState = createSelector(
    getMoveState,
    (moveState) => moveState?.electricityDoubleExclusiveNightMeterReading
);

export const getEnergyOfferSelectionState = createSelector(getEnergyOfferState, (energyOfferState) => ({
    ...energyOfferState?.energyOfferSelection,
    hasSolarPanels: energyOfferState?.hasSolarPanels,
}));

/**
 * todo remove unknown when WizardSelection is gone
 */
export const getEnergySuggestionSelectionState = <MemoizedSelector<object, EnergySuggestion>>(
    createSelector(getEnergyOfferState, (energyOfferState) => energyOfferState?.energyOfferSelection as unknown)
);

export const getEnergyTransferState = createSelector(getMoveState, (moveState) => moveState?.energyTransfer);

export const getEnergyTransferSuccessState = createSelector(getEnergyTransferState, getUserState, (energyTransferState, userState) =>
    MoveUtils.energyTransferSuccess(energyTransferState, userState)
);

export const getEnergyPaymentType = createSelector(getMoveState, (moveState) => moveState?.energyPaymentType);

export const getEnergyConfirmedState = createSelector(getMoveState, (moveState) => moveState?.energyConfirmed);

export const getEnergyTransferConfirmedState = createSelector(getMoveState, (moveState) => !!moveState?.energyTransferConfirmed);

export const getEnergySelectedState = createSelector(getMoveState, (moveState) => !!moveState?.energySelected);

export const getEnergyOrderedState = createSelector(getMoveState, (moveState) => !!moveState?.energyOrdered);

export const getEnergyOrderedByAdminState = createSelector(getMoveState, (moveState) => !!moveState?.energyOrderedByAdmin);

export const getEnergyDocumentsMovingAddressByAdminState = createSelector(
    getMoveState,
    (moveState) => !!moveState?.energyDocumentsMovingAddressByAdmin
);

export const getEnergyDocumentsCurrentAddressByAdminState = createSelector(
    getMoveState,
    (moveState) => !!moveState?.energyDocumentsCurrentAddressByAdmin
);

export const getEnergyOfferSelectedState = createSelector(getMoveState, (moveState) => MoveUtils.energyOfferSelected(moveState));

export const getEnergyTransferSelectedState = createSelector(getMoveState, (moveState) => MoveUtils.energyTransferSelected(moveState));

export const getEnergyOfferOrTransferSelectedState = createSelector(
    getEnergyOfferSelectedState,
    getEnergyTransferSelectedState,
    (energyOfferSelected, energyTransferSelected) => {
        return !!energyOfferSelected || !!energyTransferSelected;
    }
);

export const getEanCodesCompleteState = createSelector(
    getMoveState,
    (move) => move?.moveStates?.electricityEANComplete && move?.moveStates?.gasEANComplete
);

export const getMeterReadingsCompleteState = createSelector(
    getMoveState,
    (move) => move?.moveStates?.electricityEnergyStartMeterReadingsComplete && move?.moveStates?.gasEnergyStartMeterReadingsComplete
);

export const getEnergyCompleteState = createSelector(
    getMoveState,
    getEanCodesCompleteState,
    getMeterReadingsCompleteState,
    (moveState, eanCodesComplete, meterReadingsComplete) => {
        return MoveUtils.energyComplete(eanCodesComplete, meterReadingsComplete, moveState?.energyDocumentsMovingAddressByAdmin);
    }
);

export const getInsurancesOfferState = createSelector(getMoveState, (moveState) => moveState?.insurancesOffer);

export const getInsurancesOfferSelectionState = createSelector(
    getInsurancesOfferState,
    (insurancesOfferState) => insurancesOfferState?.insurancesOfferSelection
);

export const getInsurancesSelectedState = createSelector(getMoveState, (moveState) => !!moveState?.insurancesSelected);

export const getInsurancesOfferSelectedState = createSelector(
    getMoveState,
    (moveState) => !!moveState?.insurancesOffer?.insurancesOfferSelection && !!moveState?.insurancesSelected
);

export const getInsurancesProposalState = createSelector(getMoveState, (moveState) => moveState?.insurancesProposal);

export const getInsurancesProposalUrlState = createSelector(
    getInsurancesProposalState,
    (insurancesProposalState) => insurancesProposalState?.proposalUrl
);

export const getInsurancesStatesState = createSelector(getMoveState, (moveState) => moveState?.insurancesStates);

export const getInsurancesStatusIsOrderedState = createSelector(
    getInsurancesStatesState,
    (insurancesStates) => !!insurancesStates?.ordered?.value
);

export const getInsurancesStatusIsSelectedState = createSelector(
    getInsurancesStatesState,
    (insurancesStates) => !!insurancesStates?.selected?.value
);

export const getInsurancesStatusIsConfirmedState = createSelector(
    getInsurancesStatesState,
    (insurancesStates) => !!insurancesStates?.confirmed?.value
);

export const getInsurancesStatusIsPaidState = createSelector(
    getInsurancesStatesState,
    (insurancesStates) => !!insurancesStates?.paid?.value
);

export const getInsurancesCompleteState = createSelector(
    getInsurancesStatusIsOrderedState,
    getInsurancesStatusIsConfirmedState,
    getInsurancesStatusIsPaidState,
    (insurancesStatusIsOrdered, insurancesStatusIsConfirmed, insurancesStatusIsPaid) => {
        return insurancesStatusIsOrdered || insurancesStatusIsConfirmed || insurancesStatusIsPaid;
    }
);

export const getInsurancesOrderedState = createSelector(getMoveState, (moveState) => !!moveState?.insurancesOrdered);

export const getInsurancesPaymentType = createSelector(getMoveState, (moveState) => moveState.insurancesPaymentType);

export const getWaterState = createSelector(getMoveState, (moveState) => moveState?.water);

export const getWaterInfoCurrentAddressState = createSelector(getWaterState, (waterState) => waterState?.waterInfoCurrentAddress);

export const getWaterInfoMovingAddressState = createSelector(getWaterState, (waterState) => waterState?.waterInfoMovingAddress);

export const getWaterDocumentsMovingAddressByAdminState = createSelector(
    getMoveState,
    (moveState) => moveState?.waterDocumentsMovingAddressByAdmin
);

export const getWaterDocumentsCurrentAddressByAdminState = createSelector(
    getMoveState,
    (moveState) => moveState?.waterDocumentsCurrentAddressByAdmin
);

export const getLegalState = createSelector(getMoveState, (moveState) => moveState?.legal || {});

export const getLegalHopalaOptInPartnerState = createSelector(getLegalState, (legalState) => !!legalState?.hopalaOptInPartner);

export const getAdditionalNotesState = createSelector(getMoveState, (moveState) => moveState?.additionalNotes);

export const getInterestedState = createSelector(getMoveState, (moveState) => moveState?.interested);

export const getRealEstateGroupServicesState = createSelector(getMoveState, (moveState) => moveState?.realEstateGroupServices);

export const getRealEstateGroupServicesInsurancesEnabledState = createSelector(
    getRealEstateGroupServicesState,
    (realEstateGroupServices) => realEstateGroupServices?.insurances === true
);

export const getRealEstateGroupServicesInsurancesDisabledState = createSelector(
    getRealEstateGroupServicesState,
    (realEstateGroupServices) => realEstateGroupServices?.insurances === false
);

export const getRealEstateGroupServicesWaterDisabledState = createSelector(
    getRealEstateGroupServicesState,
    (realEstateGroupServices) => realEstateGroupServices?.water === false
);

export const getMinimalInformationState = createSelector(getRoleState, getMoveState, getUserState, (roleState, moveState, userState) => {
    if (roleState === Role.RealEstateAgent) {
        return !!userState && !!userState.firstName && !!userState.movingAddress;
    } else {
        return !!userState && !!userState.firstName && !!userState.movingAddress && !!moveState && !!moveState.movingDate;
    }
});

/**
 * Get show shopping cart state
 * Not return true when offer is already ordered by the mover
 */
export const getShowShoppingCartState = createSelector(
    getEnergyOfferSelectedState,
    getEnergyTransferSelectedState,
    getEnergyOrderedState,

    getTelecomOfferSelectedState,
    getTelecomTransferSelectedState,
    getTelecomOrderedState,

    getInsurancesOfferSelectedState,
    getInsurancesOrderedState,
    getInsurancesProposalState,

    // @ts-ignore
    (
        energyOfferSelected,
        energyTransferSelected,
        energyOrdered,
        telecomOfferSelected,
        telecomTransferSelected,
        telecomOrdered,
        insurancesOfferSelected,
        insurancesOrdered,
        insurancesProposal
    ) => {
        return (
            ((energyOfferSelected || energyTransferSelected) && !energyOrdered) ||
            ((telecomOfferSelected || telecomTransferSelected) && !telecomOrdered) ||
            (insurancesOfferSelected && !insurancesOrdered && !insurancesProposal)
        ); // don't show insurances when there is already an insurances proposal ( offer POST'ed at Hopala )
    }
);

export const getAmountCanOrderState = createSelector(
    getEnergyOfferSelectedState,
    getEnergyOrderedState,
    getTelecomOfferSelectedState,
    getTelecomOrderedState,
    getInsurancesOfferSelectedState,
    getInsurancesOrderedState,
    (energyOfferSelected, energyOrdered, telecomOfferSelected, telecomOrdered, insurancesSelected, insurancesOrdered) => {
        let amount = 0;
        if (energyOfferSelected && !energyOrdered) amount++;
        if (telecomOfferSelected && !telecomOrdered) amount++;
        if (insurancesSelected && !insurancesOrdered) amount++;
        return amount;
    }
);

const getUiOffersEnergyState = createSelector(
    getEnergyOfferSelectionState,
    getEnergyTransferState,
    getEnergySelectedState,
    getEnergyOrderedState,
    (energyOfferSelection, energyTransfer, energySelected, energyOrdered) => {
        const offers: UiOffer[] = [];
        if (!!energySelected && !energyOrdered) {
            if (energyOfferSelection) {
                offers.push({
                    type: UiOfferType.Energy,
                    suggestion: energyOfferSelection,
                    payable: true,
                });
            }
            if (energyTransfer) {
                offers.push({
                    type: UiOfferType.EnergyTransfer,
                    payable: false,
                });
            }
        }
        return offers;
    }
);

const getUiOffersTelecomState = createSelector(
    getTelecomOfferSelectionState,
    getTelecomTransferState,
    getTelecomSelectedState,
    getTelecomOrderedState,
    (telecomOfferSelection, telecomTransfer, telecomSelected, telecomOrdered) => {
        const offers: UiOffer[] = [];
        if (!!telecomSelected && !telecomOrdered) {
            if (telecomOfferSelection) {
                offers.push({
                    type: UiOfferType.Telecom,
                    suggestion: telecomOfferSelection,
                    payable: true,
                });
            }
            if (telecomTransfer) {
                offers.push({
                    type: UiOfferType.TelecomTransfer,
                    payable: false,
                });
            }
        }
        return offers;
    }
);

const getUiOffersInsurancesState = createSelector(
    getInsurancesOfferSelectionState,
    getInsurancesSelectedState,
    getInsurancesOrderedState,
    getInsurancesProposalState,
    (insurancesOfferSelection, insurancesSelected, insurancesOrdered, insurancesProposal) => {
        const offers: UiOffer[] = [];
        // don't create offer when there is already an insurances proposal ( offer POST'ed at Hopala )
        if (!!insurancesSelected && !!insurancesOfferSelection && !insurancesOrdered && !insurancesProposal) {
            offers.push({
                type: UiOfferType.Insurances,
                suggestion: insurancesOfferSelection,
                payable: true,
            });
        }
        return offers;
    }
);

export const getShoppingCartState = createSelector(
    getUiOffersEnergyState,
    getUiOffersTelecomState,
    getUiOffersInsurancesState,
    (uiOffersEnergy, uiOffersTelecom, uiOffersInsurances) => {
        return [...uiOffersEnergy, ...uiOffersTelecom, ...uiOffersInsurances];
    }
);

export const getPayableShoppingCartState = createSelector(getShoppingCartState, (shoppingCartState) =>
    shoppingCartState.filter((uiOffer) => !!uiOffer.payable)
);

export const getPayableShoppingCartLengthState = createSelector(
    getPayableShoppingCartState,
    (payableShoppingCartState) => payableShoppingCartState?.length || 0
);

export const getPayableShoppingCartWithoutInsurancesState = createSelector(getPayableShoppingCartState, (payableShoppingCartState) =>
    payableShoppingCartState.filter((uiOffer) => uiOffer.type !== UiOfferType.Insurances)
);

export const getPayableShoppingCartWithoutInsurancesLengthState = createSelector(
    getPayableShoppingCartWithoutInsurancesState,
    (payableShoppingCartState) => payableShoppingCartState?.length || 0
);

/**
 * When prop is false, only return true when this product is not selected,
 * when prop is null, is will return true
 */
export const getCanOrderState = createSelector(
    getEnergyOfferSelectedState,
    getEnergyTransferSelectedState,
    getEnergyOrderedState,

    getTelecomOfferSelectedState,
    getTelecomTransferSelectedState,
    getTelecomOrderedState,

    getInsurancesOfferSelectedState,
    getInsurancesOrderedState,
    MoveUtils.handleCanOrderStateSelector
);

export const getShowMajorListState = createSelector(
    getEnergyOfferSelectedState,
    getEnergyTransferSelectedState,

    getTelecomOfferSelectedState,
    getTelecomTransferSelectedState,

    getInsurancesOfferSelectedState,

    selectOrderedBoilerMaintenanceContract,
    (
        energyOfferSelected,
        energyTransferSelected,
        telecomOfferSelected,
        telecomTransferSelected,
        insurancesSelected,
        orderedBoilerMaintenanceContract
    ) => {
        return (
            (!energyOfferSelected && !energyTransferSelected) ||
            (!telecomOfferSelected && !telecomTransferSelected) ||
            !insurancesSelected ||
            !DbUtils.getStringId(orderedBoilerMaintenanceContract)
        );
    }
);

export const getShowEnergyCompleteDataState = createSelector(
    getEnergyTransferSelectedState,
    getEnergyOrderedState,
    (energyTransferSelected, energyOrdered) => {
        return energyTransferSelected || energyOrdered;
    }
);

export const getShowTelecomCompleteDataState = createSelector(
    getTelecomTransferSelectedState,
    getTelecomOrderedState,
    (telecomTransferSelected, telecomOrdered) => {
        return telecomTransferSelected || telecomOrdered;
    }
);

export const getShowInsurancesCompleteDataState = createSelector(
    getInsurancesOrderedState,
    getInsurancesProposalUrlState,
    getInsurancesStatesState,
    (insurancesOrdered, insurancesProposalUrl, insurancesStates) => {
        // show complete state when there is a insurances proposal
        return (insurancesOrdered || !!insurancesProposalUrl) && !insurancesStates?.ordered?.value;
    }
);

export const getShowCompleteDataState = createSelector(
    getShowEnergyCompleteDataState,
    getEnergyCompleteState,

    getShowTelecomCompleteDataState,
    getTelecomCompleteState,

    getShowInsurancesCompleteDataState,
    getInsurancesCompleteState,

    selectOrderedBoilerMaintenanceContract,
    (
        showEnergyComplete,
        energyComplete,
        showTelecomComplete,
        telecomComplete,
        showInsurancesComplete,
        insurancesComplete,
        orderedBoilerMaintenanceContract
    ) => {
        return (
            (showEnergyComplete && !energyComplete) ||
            (showTelecomComplete && !telecomComplete) ||
            (showInsurancesComplete && !insurancesComplete) ||
            DbUtils.getStringId(orderedBoilerMaintenanceContract)
        );
    }
);

export const getIsEnergyCompleteState = createSelector(
    getEnergyOfferSelectedState,
    getEnergyTransferSelectedState,
    getEnergyOrderedState,
    getEnergyCompleteState,
    (energyOfferSelected, energyTransferSelected, energyOrdered, energyComplete) => {
        return (energyOfferSelected || energyTransferSelected) && energyOrdered && energyComplete;
    }
);

export const getIsInsurancesCompleteState = createSelector(
    getInsurancesOfferSelectedState,
    getInsurancesCompleteState,
    (insurancesOfferSelected, insurancesComplete) => {
        return insurancesOfferSelected && insurancesComplete;
    }
);

export const getIsSomeCompleteState = createSelector(
    getIsEnergyCompleteState,
    getIsTelecomCompleteState,
    getIsInsurancesCompleteState,
    (isEnergyComplete, isTelecomComplete, isInsurancesComplete) => {
        return isEnergyComplete || isTelecomComplete || isInsurancesComplete;
    }
);

export const getHasContactDetailsState = createSelector(getMoveState, (moveState) => {
    return (
        !!moveState?._id &&
        !!moveState?.user?.firstName &&
        !!moveState?.user?.lastName &&
        !!moveState?.user.dateOfBirth &&
        !!moveState?.user.phoneNumber &&
        !!moveState?.user.email
    );
});

export const getIdState = createSelector(getMoveState, (moveState) => moveState?._id);

export const getNoIdState = createSelector(getMoveState, (moveState) => !moveState?._id);

export const getSepaRequiredState = createSelector(getMoveState, (moveState) => {
    return moveState?.energyPaymentType === PaymentType.Domiciliation || moveState?.telecomPaymentType === PaymentType.Domiciliation;
});

export const getVacancyState = createSelector(getEnergyOfferState, (energyOfferState) => !!energyOfferState?.vacancy);

export const getProfessionalState = createSelector(getMoveState, (moveState) => !!moveState?.professional);

export const getContactLogsState = createSelector(getMoveState, (moveState) => moveState?.contactLogs);

export const getHasCallInContactLogsState = createSelector(getContactLogsState, (contactLogs) =>
    MoveUtils.getHasCallInContactLogs(contactLogs)
);

export const getServiceActionsAsStringState = createSelector(
    getEnergyOrderedByAdminState,
    getTelecomOrderedByAdminState,
    getEnergyDocumentsMovingAddressByAdminState,
    getEnergyDocumentsCurrentAddressByAdminState,
    getWaterDocumentsMovingAddressByAdminState,
    getWaterDocumentsCurrentAddressByAdminState,
    (
        energyOrderedByAdmin,
        telecomOrderedByAdmin,
        energyDocumentsMovingAddressByAdmin,
        energyDocumentsCurrentAddressByAdmin,
        waterDocumentsMovingAddressByAdmin,
        waterDocumentsCurrentAddressByAdmin
    ) => {
        return MoveUtils.getServiceActionsAsString(
            energyOrderedByAdmin,
            telecomOrderedByAdmin,
            energyDocumentsMovingAddressByAdmin,
            energyDocumentsCurrentAddressByAdmin,
            waterDocumentsMovingAddressByAdmin,
            waterDocumentsCurrentAddressByAdmin
        );
    }
);
